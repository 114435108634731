<template>
  <v-app id="app">
    <div class="bg-design-box bg-design-2"></div>
    <div class="bg-design-box bg-design-3"></div>
    <v-main class="bg-image">
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <div class="text-center mb-4">
              <a href="//consark.ai">
                <img
                  src="/img/consark/logo.png"
                  class=""
                  style="max-width: 300px"
                  alt="consark-logo"
                />
              </a>
              <div class="body-1 font-weight-bold">
                Unified Controllership Platform
              </div>
            </div>
            <div v-if="gologin">
              <v-card
                :loading="loadingcard"
                :class="{
                  'mb-4 shadow2-a pb-1 px-1': true,
                  'px-3': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-card-text class="mt-3">
                  <v-row>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.username"
                        label="Name*"
                        :error="userListerror.username"
                      ></lb-string>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.contactemail"
                        label="Email*"
                        :error="userListerror.contactemail"
                      ></lb-string>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.designation"
                        label="Designation*"
                        :error="userListerror.designation"
                      ></lb-string>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.enityname"
                        label="Entity Name*"
                        :error="userListerror.enityname"
                      ></lb-string>
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                      <lb-string
                        v-model="userList.country"
                        label="Country*"
                        :error="userListerror.country"
                      ></lb-string>
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                      <lb-string
                        v-model="userList.state"
                        label="State*"
                        :error="userListerror.state"
                      ></lb-string>
                    </v-col>
                    <v-col cols="12" class="my-0 py-0">
                      <lb-string
                        v-model="userList.contactno"
                        label="Mobile"
                      ></lb-string>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <div class="mt-2">
                <v-btn
                  color="primary ma-0"
                  block
                  elevation="0"
                  @click="addself()"
                  >Submit</v-btn
                >
              </div>
            </div>
            <div v-else>
              <v-card
                :class="{
                  'shadow2-a pa-1': true,
                  'pa-3': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-card-text class="text-center">
                  <v-icon
                    style="font-size: 70px !important"
                    class="success--text"
                    >mdi-check-circle-outline</v-icon
                  >
                  <div class="">
                    We have sent an email to <b>{{ userList.email }}</b
                    >.<br />
                    Please follow the same instructions to email
                  </div>
                  <div class="pt-3">
                    <v-btn color="primary" elevation="0" to="/" small>
                      <v-icon left>mdi-home</v-icon>Go to Login</v-btn >
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <div class="text-center mt-4">
              <div class="text-center mt-2">
                <a
                  href="https://consark.ai/policies/termsofuse/"
                  target="_blank"
                >
                  <v-btn class="shadow-off primary--text mr-2" outlined small
                    >Terms of Use & Service</v-btn
                  >
                </a>
                <a
                  href="https://consark.ai/policies/privacypolicy/"
                  target="_blank"
                >
                  <v-btn class="shadow-off primary--text" outlined small
                    >Privacy Policy</v-btn
                  >
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
export default {
  data() {
    return {
      userList: {},
      userListerror: {},
      gologin: true,
      loadingcard: false,
    };
  },
  methods: {
    addself() {
         console.log(this.userList);
        //  this.gologin = false;
        this.loadingcard = true;
        let url = "/v2/self_service/add";
        this.axios
          .post(url,this.userList)
          .then((dt) => {
            if (dt.data.status === "success") {
              this.gologin = false;
              this.$store.commit("sbSuccess", "Tasklist item deleted");
            } else {
              if (dt.data.data.length > 0) {
                if (dt.data.data[0].index0)
                  this.userListerror = dt.data.data[0].index0;
              } else throw new Error(dt.data.message);
            }
          })
          .catch((err) => {
            this.gologin = true;
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loadingcard = false;
          });
    },
  },
  created() {
    this.$store.commit("addLoading");
    this.$nextTick(() => {
      this.$store.commit("removeLoading");
    });
  },
};
</script>
